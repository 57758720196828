<template>
 <div class="mt-5">
   <filtros/>
   <tabla-tutelas class="mt-5"/>
 </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'ProcesosJuridicosTutelasListado',
  components: {
    filtros: defineAsyncComponent(
      () => import('./components/filtros.vue')
    ),
    TablaTutelas: defineAsyncComponent(
      () => import('./components/tablaTutelas.vue')
    )
  }
}
</script>
<style scoped>

</style>
